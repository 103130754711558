<style lang="scss">
.npc-badge {
  @apply w-auto uppercase relative;
  max-width: 460px;
  padding: 8px 24px 8px 104px;
  background: #ffffff;
  border: 2px solid #dde0f1;
  border-radius: 8px;
  &__title {
    @apply font-vtb-demibold mb-1;
    font-size: 12px;
    line-height: 16px;
    color: #009fdf;
  }
  &__subtitle {
    @apply font-vtb-bold;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
  }
  &__img {
    @apply absolute bg-no-repeat bg-center;
    --size: 68px;
    height: var(--size);
    width: var(--size);
    background-image: url('/images/npc-badge.svg');
    bottom: 9px;
    left: 16px;
  }
}
</style>

<template>
  <div class="npc-badge">
    <div class="npc-badge__img" />
    <div class="npc-badge__title">
      {{ title }}
    </div>
    <div class="npc-badge__subtitle">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'NpcBadge',
  components: {},
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
