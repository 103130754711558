<style lang="scss">
.l-question {
  &__pending {
    margin-top: 175px;
    &-image {
      @apply mx-auto relative;
      --size: 180px;
      width: var(--size);
      height: var(--size);
    }
    &-symbol {
      @apply absolute font-vtb-bold;
      font-size: 28px;
      line-height: 28px;
      color: #0094ff;
      top: 56px;
      left: 80px;
    }
    &-title {
      @apply font-vtb-bold mt-12 text-center;
      font-size: 28px;
      line-height: 28px;
      color: #000000;
    }
  }
}
</style>

<template>
  <div class="l-question__pending">
    <div class="l-question__pending-image">
      <div class="l-question__pending-symbol">{{ currentSymbol }}</div>
      <icon-computer />
    </div>
    <div class="l-question__pending-title">
      {{ currentTitle
      }}<span class="w-32px inline-block text-left">{{
        '.'.repeat(dotesNumber)
      }}</span>
    </div>
  </div>
</template>

<script>
import IconComputer from '../Icons/IconComputer.vue';
export default {
  name: 'LandingQuestionLoader',
  components: { IconComputer },
  data() {
    return {
      currentTitleIndex: 0,
      dotesNumber: 0,
      symbols: '@9+#$=4%&1*',
      symbolIndex: 0,
      titles: [
        'Бип-буп. Обрабатываю ответ',
        'Загрузка сложных вопросов',
        'Наливаем воду в модули памяти',
        'Готовим карту переходов искуственного интилекта',
        'Тушим случайны прорыв данных',
        'Затыкаем дырки на сервере',
        'Обрезаем провода что бы не мешались',
      ],
      titleInterval: null,
      dotsInterval: null,
      symbolInterval: null,
    };
  },
  props: {},
  created() {},
  mounted() {
    this.titleInterval = setInterval(this.setNextIdx, 1000);
    this.dotsInterval = setInterval(this.setDotsNumber, 300);
    this.symbolInterval = setInterval(this.setNextSymbol, 100);
  },
  methods: {
    setDotsNumber() {
      if (this.dotesNumber < 3) {
        this.dotesNumber += 1;
      } else {
        this.dotesNumber = 0;
      }
    },
    setNextIdx() {
      if (this.currentTitleIndex === this.titles.length - 1) {
        this.currentTitleIndex = 0;
      } else {
        this.currentTitleIndex += 1;
      }
    },
    setNextSymbol() {
      if (this.symbolIndex === this.symbols.length - 1) {
        this.symbolIndex = 0;
      } else {
        this.symbolIndex += 1;
      }
    },
  },
  computed: {
    currentTitle() {
      return this.titles[this.currentTitleIndex];
    },
    currentSymbol() {
      return this.symbols[this.symbolIndex];
    },
  },
  destroyed() {
    clearInterval(this.titleInterval);
    clearInterval(this.dotsInterval);
    clearInterval(this.symbolInterval);
  },
};
</script>
