<style lang="scss">
.wrapper {
  background: green;
  color: white;
}
.l-top {
  @apply bg-no-repeat bg-contain;
  background-position: top center;
  background-image: url('/images/landing-top-bg.svg');
}
.l-block {
  @apply mx-auto px-4 md:px-20 relative;
  max-width: 1280px;
  &__content {
    @apply relative z-10;
  }
}
.l-button-login {
  @apply sticky mx-4;
  top: 80%;
  z-index: 9;
  width: calc(100% - 32px);
}
@screen md {
  .l-button-login {
    @apply sticky w-auto;
    margin-left: auto;
    margin-right: 20%;
  }
}
.l-blur {
  @apply bg-no-repeat absolute z-0 left-0;
  -webkit-mask-image: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 10%,
    rgba(0, 0, 0, 0.8) 20%,
    rgba(0, 0, 0, 1) 30%,
    rgba(0, 0, 0, 1) 70%,
    rgba(0, 0, 0, 0) 100%
  );
  mask-image: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 10%,
    rgba(0, 0, 0, 0.8) 20%,
    rgba(0, 0, 0, 1) 30%,
    rgba(0, 0, 0, 1) 70%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
  background-image: url('/images/landing-blur-1.svg');
}
.l-blur-1 {
  @apply md:-top-12 md:bg-center;
  background-size: 150%;
  top: -50px;
  background-position: top;
  height: 140%;
}
.l-blur-2 {
  @apply bg-center;
  height: 100%;
  top: 80px;
}
.l-blur-3 {
  @apply md:-top-12 md:bg-center;
  background-size: 150%;
  top: 150px;
  background-position: top;
  height: 140%;
}
.l-header {
  @apply flex justify-between items-center text-sm md:text-base uppercase leading-6;
}
.l-title {
  @apply uppercase font-vtb-bold-italic;
  letter-spacing: 0.01em;
  color: #000000;
  font-size: 37px;
  line-height: 40px;
  margin-top: 69px;
  & span {
    @apply inline-block;
  }
  &__cjm {
    @apply mr-1 md:mr-2 relative top-2 md:top-7;
    width: 40px;
    height: 40px;
  }
}
.l-text-outline {
  @apply inline-block font-vtb-bold;
  font-style: initial;
  -webkit-text-stroke: 2px #000000;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.11em;
}
.l-hand {
  @apply relative;
}
.j-cje-letter {
  @apply mb-5 md:mb-0;
  &--c {
    width: 35%;
  }
  &--j {
    width: 27%;
  }
  &--e {
    width: 35%;
  }
  &__container {
    @apply flex flex-col md:flex-row items-center md:items-start justify-between mt-10 md:mt-36
      mb-12 md:mb-36;
  }
}
.l-vtb-consult__container {
  background: #f1f7fa;
}
@screen md {
  .l-header {
    line-height: 30px;
  }
  .l-title {
    font-size: 89px;
    line-height: 96px;
    margin-top: 134px;
    &__cjm {
      width: 114px;
      height: 114px;
    }
  }
  .l-cje {
    -webkit-text-stroke: 4px #000000;
  }
  .l-blur-1 {
    background-size: 100%;
  }
  .l-blur-3 {
    top: -50px;
  }
  /*.l-hand {
    &:before {
      content: "";
      @apply absolute right-4 md:right-20;
      height: 70%;
      width: 1px;
      background: #000000;
      top: 17%;
    }
  } */
  .l-text-outline {
    -webkit-text-stroke: 4.5px #000000;
  }
}
.l-header__logo {
  width: 58px;

  @screen md {
    width: 100px;
  }
}
</style>

<template>
  <div class="landing">
    <div class="l-block md:pt-11 pt-6 text-left">
      <img
        src="/images/vtb-logo.png"
        alt="vtb logo"
        class="l-header__logo inline-block"
      />
    </div>

    <div class="relative">
      <div class="l-block pt-12 md:pt-14 pb-16 md:pb-28">
        <landing-cje-descr />
      </div>

      <div class="l-block">
        <h4 class="l-why-cje-title">Почему Customer Journey Expert?</h4>
      </div>

      <div class="l-block relative">
        <div class="l-blur l-blur-2"></div>
        <landing-why-cje />
      </div>

      <div class="l-block">
        <landing-cje-tasks />
      </div>
    </div>

    <div class="l-block">
      <landing-share />
    </div>

    <div class="l-block">
      <footer-content />
    </div>
  </div>
</template>

<script>
import LandingCjeDescr from '../components/LandingPage/LandingCjeDescr.vue';
import LandingWhyCje from '../components/LandingPage/LandingWhyCje.vue';
import LandingCjeTasks from '../components/LandingPage/LandingCjeTasks.vue';
import LandingShare from '../components/LandingPage/LandingShare.vue';
import FooterContent from '../components/FooterContent.vue';

export default {
  components: {
    LandingCjeDescr,
    LandingWhyCje,
    LandingCjeTasks,
    LandingShare,
    FooterContent,
  },
  name: 'Home',
  data() {
    return {};
  },
  mounted() {},
  methods: {
    login() {
      this.$store.dispatch('user/login');
    },
    toGame() {
      this.$router.push({ name: 'Game' });
    },
    onEnd() {
      // console.log("end");
    },
  },
  computed: {
    isLogined() {
      return this.$store.getters['user/isLogined'];
    },
  },
};
</script>
