<style lang="scss" scoped>
.share {
  @apply py-4 md:py-6 px-3 md:px-8 bg-no-repeat;
  background-image: url('/images/scraper.svg'),
    url('/images/results-share-potator.svg'),
    url('/images/results-share-bg.png'), url('/images/blur-share.svg');
  background-size: 20%, 45%, contain, auto;
  background-position: bottom right, bottom right, top, bottom right;
  border-radius: 4px;
  border: 1px solid #000000;
  color: #000000;
  &__content {
  }
  &__title {
    @apply font-vtb-bold-italic uppercase;
    font-weight: 800;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.01em;
    &--shadow {
      @apply text-white;
      font-style: normal;
      text-shadow: 1px 1px 2px black, 1px -1px 2px black, -1px 1px 2px black,
        -1px -1px 2px black;
    }
  }
  &__subtitle {
    @apply font-vtb-regular;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
  }
  &__item-container {
    --side-indent: 6px;
    margin-left: calc(-1 * var(--side-indent));
    margin-right: calc(-1 * var(--side-indent));
  }
  &__item {
    @apply inline-block align-top;
    margin-left: var(--side-indent);
    margin-right: var(--side-indent);
  }
}
@screen md {
  .share {
    background-size: auto, auto, contain, auto;
    &__content {
    }
    &__title {
      font-size: 40px;
      line-height: 52px;
    }
    &__subtitle {
      font-size: 20px;
      line-height: 28px;
    }
  }
}
</style>

<template>
  <div class="share">
    <icon-vtb class="w-56px md:w-100px mb-4" />
    <slot name="result-title"></slot>
    <div class="share__subtitle mb-4">
      Расскажи друзьям,<br />насколько крутая игра
    </div>
    <div class="share__item-container">
      <a
        v-for="social in socialNetworks"
        :key="social"
        :href="shareUrlLink(social)"
        class="share__item"
        target="_blank"
      >
        <slot :name="social">
          <component :is="`icon-${social}-rounded`" />
        </slot>
      </a>
    </div>
  </div>
</template>

<script>
import IconTwRounded from '@/components/Icons/IconTwRounded.vue';
import IconVkRounded from '@/components/Icons/IconVkRounded.vue';
import IconVtb from '../components/Icons/IconVtb.vue';

const DEFAULT_SOCIAL_NETWORKS = ['vk', 'tw'];
const VK_BASE_SHARE_LINK = 'https://vk.com/share.php?url=';
const TW_BASE_SHARE_LINK = 'https://www.twitter.com/share?url=';

export default {
  name: 'SocialShare',
  components: { IconVkRounded, IconTwRounded, IconVtb },
  data() {
    return {};
  },
  props: {
    mainTitle: String,
    title: { type: [String, Boolean], default: 'Поделись игрой с друзьями' },
    shareUrl: { type: String },
    socialNetworks: {
      type: Array,
      validator: function (value) {
        return value.every((item) => DEFAULT_SOCIAL_NETWORKS.includes(item));
      },
      default: () => ['vk', 'tw'],
    },
  },
  methods: {
    shareUrlLink(platform) {
      const url = this.shareUrl || window.location.href;
      switch (platform) {
        case 'tw':
          return TW_BASE_SHARE_LINK + url;
        case 'vk':
          return VK_BASE_SHARE_LINK + url;
      }
    },
  },
  computed: {},
  watch: {},
  mounted() {},
};
</script>
