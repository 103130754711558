<template>
  <svg width="28" height="40" viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 5.37598V19.68L9.456 16.416V8.47998L6 5.37598ZM22.384 19.68V5.37598L18.928 8.67198V16.576L22.384 19.68ZM9.64798 21.9841C8.90131 21.3867 8.14398 20.8001 7.37598 20.2241L9.64798 18.5281H18.384L20.624 20.2241L18.384 21.9841H9.64798ZM18.928 31.808L22.384 35.104V20.8L18.928 23.936V31.808Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconDigitFour',
  components: {},
  data() {
    return {};
  },
  props: {},
  created() { },
  mounted() { },
  methods: {},
  computed: {},
};
</script>
