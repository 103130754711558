<style lang="scss">
.l-question {
  @apply bg-no-repeat relative overflow-hidden;
  min-height: 100vh;
  background-image: url('/images/landing/question-blur.svg'),
    url('/images/landing-top-bg.png');
  background-position: center, top left;
  background-size: 800px, contain;
  &__title {
    @apply font-vtb-bold-italic uppercase;
    font-weight: 800;
    font-size: 28px;
    line-height: 42px;
    letter-spacing: 0.01em;
    color: #000000;
    letter-spacing: 0.01em;
    & span {
      @apply inline-block;
      font-style: initial;
      -webkit-text-stroke: 2px #000000;
      -webkit-text-fill-color: transparent;
    }

    @media screen and (min-width: 769px) {
      font-size: 60px;
      line-height: 84px;
    }
  }
  &__text {
    @apply font-vtb-regular mb-8;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    padding-right: 48px;

    @media screen and (min-width: 769px) {
      @apply mb-0;
    }
  }
  &__answers {
    @apply w-full flex-shrink-0;
    margin: -10px;

    @media screen and (min-width: 769px) {
      width: 568px;
    }
  }
  &__answer-item {
    @apply relative block align-top text-left;
    margin: 10px;
    width: 100%;
    padding: 12px 16px 12px 48px;
    border: 1px solid #000000;
    border-radius: 4px;
    height: 72px;
    background: #ffffff;
    &:hover {
      background: rgba(0, 159, 223, 0.5);
    }

    @media screen and (min-width: 769px) {
      @apply inline-block;
      width: 264px;
    }
  }
  &__answer-index {
    @apply absolute rounded-full text-center font-vtb-demibold;
    top: 50%;
    left: 16px;
    background: #000000;
    --size: 16px;
    width: var(--size);
    height: var(--size);
    font-size: 9px;
    line-height: var(--size);
    color: #ffffff;
    transform: translateY(-50%);

    @media screen and (min-width: 769px) {
      top: 16px;
      transform: translateY(0%);
    }
  }
}
.l-question-container {
  @apply mx-auto;
  max-width: 1280px;
  padding-top: 100px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 44px;

  @media screen and (min-width: 769px) {
    padding-top: 14%;
    padding-left: 96px;
    padding-right: 72px;
    padding-bottom: 72px;
  }
}

.marque-text {
  @apply hidden mx-1 uppercase font-vtb-bold;
  font-weight: 800;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #009fdf;

  @media screen and (min-width: 1024px) {
    @apply block;
  }
}
.l-question__fire-1 {
  width: 100%;
  background-image: url('/images/landing/fire-01.png');
  height: 350px;
  background-size: 1280px;
  background-repeat: repeat no-repeat;
  background-position: bottom;
  position: absolute;
  animation: fire-1 5s 2 ease-in-out;
  animation-fill-mode: forwards;
  animation-direction: alternate;

  z-index: 99;
}

@keyframes fire-1 {
  from {
    bottom: -294px;
  }
  40% {
    bottom: 0;
  }
  to {
    bottom: 0;
  }
}

.l-question__fire-partical-1 {
  width: 100%;
  background-image: url('/images/landing/fire-particle-01.png');
  height: 120px;
  background-size: 1280px;
  background-repeat: repeat no-repeat;
  background-position: bottom;
  position: absolute;
  animation: fire-partical-1 5s 2 ease-in-out;
  animation-fill-mode: forwards;
  animation-direction: alternate;

  z-index: 999;
}

@keyframes fire-partical-1 {
  from {
    bottom: -120px;
  }
  60% {
    bottom: 240px;
  }
  to {
    bottom: 240px;
  }
}

.l-question__fire-2 {
  width: 100%;
  background-image: url('/images/landing/fire-02.png');
  height: 350px;
  background-size: 1280px;
  background-repeat: repeat no-repeat;
  background-position: bottom;
  position: absolute;
  animation: fire-2 5s 2 ease-in-out;
  animation-fill-mode: forwards;
  animation-direction: alternate;

  z-index: 9;
}

@keyframes fire-2 {
  from {
    bottom: -350px;
  }
  to {
    bottom: calc(100% - 300px);
  }
}

.l-question__fire-partical-2 {
  width: 100%;
  background-image: url('/images/landing/fire-particle-02.png');
  height: 120px;
  background-size: 1280px;
  background-repeat: repeat no-repeat;
  background-position: bottom;
  position: absolute;
  animation: fire-partical-2 5s 2 ease-in-out;
  animation-fill-mode: forwards;
  animation-direction: alternate;

  z-index: 9;
}

@keyframes fire-partical-2 {
  from {
    bottom: -120px;
  }
  to {
    bottom: 472px;
  }
}

.l-question__white-bg {
  width: 100%;
  height: 100vh;
  position: absolute;
  animation: white-bg 5s 2 ease-in-out;
  animation-fill-mode: forwards;
  animation-direction: alternate;

  z-index: 1;
  background: #ffffff;
}

@keyframes white-bg {
  from {
    opacity: 0;
    top: 100%;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

.marquee-top {
  @apply absolute top-2;
  width: calc(100% - 32px);
  left: 16px;
}

.marquee-bottom {
  @apply absolute bottom-4;
  width: calc(100% - 32px);
  left: 16px;
}

.marquee-left {
  @apply absolute;
  transform: rotate(270deg);
  left: calc(-100vh / 2 + 56px);
  top: calc(100vh / 2 - 16px);
  width: calc(100vh - 70px);
}

.marquee-right {
  @apply absolute;
  transform: rotate(90deg);
  right: calc(-100vh / 2 + 56px);
  top: calc(100vh / 2 - 16px);
  width: calc(100vh - 70px);
}

.logo {
  width: 58px;
  height: 20px;

  @media screen and (min-width: 769px) {
    width: 89px;
    height: 37px;
  }
}
</style>

<template>
  <div class="l-question">
    <marquee-text :repeat="5" class="marquee-top">
      <span class="marque-text">{{ runningText }}</span>
    </marquee-text>
    <marquee-text :repeat="5" class="marquee-bottom">
      <span class="marque-text">{{ runningText }}</span>
    </marquee-text>
    <marquee-text :repeat="5" class="marquee-left">
      <span class="marque-text">{{ runningText }}</span>
    </marquee-text>
    <marquee-text :repeat="5" class="marquee-right">
      <span class="marque-text">{{ runningText }}</span>
    </marquee-text>
    <icon-vtb class="absolute md:top-72px top-8 md:left-16 left-4 logo" />
    <!-- fire animation blocks -->
    <template v-if="burn">
      <div class="l-question__fire-1"></div>
      <div class="l-question__fire-partical-1"></div>
      <div class="l-question__fire-2"></div>
      <div class="l-question__fire-partical-2"></div>
      <div class="l-question__white-bg"></div>
    </template>

    <!-- main content -->
    <transition-fade>
      <div
        v-if="currentStage === stages.QUESTION"
        key="question"
        class="l-question-container"
      >
        <div class="l-question__title md:mb-60px mb-32px">
          Решай
          <digital-timer
            :interval="90"
            class="inline-block mx-4"
            @onStop="launchFire"
          />скорее, <br />прод горит,
          <span>команда стоит</span>
        </div>
        <div class="flex flex-col md:flex-row">
          <div class="l-question__text">{{ questionText }}</div>
          <div class="l-question__answers">
            <button
              v-for="(answer, idx) in answers"
              :key="answer"
              class="l-question__answer-item"
              @click="handleAnswer(answer)"
            >
              <div class="l-question__answer-index">{{ idx + 1 }}</div>
              {{ answer }}
            </button>
          </div>
        </div>
      </div>
      <landing-question-loader
        v-else-if="currentStage === stages.PENDING"
        key="pending"
      />
      <div v-else-if="currentStage === stages.RESULT">
        <result-block
          :supertitle="supertitle"
          :title="title"
          :subtitle="subtitle"
          :withBadge="withBadge"
        >
          <template v-if="withBadge" #top>
            <npc-badge
              class="mb-6 md:mb-10 mx-auto"
              :title="`${$randomNumber(
                20,
                70
              )}% сделали такой же выбор, как и ты`"
              :subtitle="userChoice"
            />
          </template>
          <router-link
            :to="{ name: 'AboutCJM' }"
            class="l-question-result__button l-g-btn l-g-btn--outline mr-4"
          >
            Расскажите про CJM
          </router-link>
          <button-login class="l-question-result__button" text="Начать игру" />
        </result-block>
      </div>
    </transition-fade>
  </div>
</template>

<script>
import IconVtb from '../Icons/IconVtb.vue';
import DigitalTimer from '../UI/DigitalTimer.vue';
import MarqueeText from 'vue-marquee-text-component';
import ResultBlock from '@/components/UI/ResultBlock.vue';
import ButtonLogin from '@/components/ButtonLogin.vue';
import LandingQuestionLoader from './LandingQuestionLoader.vue';
import TransitionFade from '../Animation/TransitionFade.vue';
import NpcBadge from '../UI/NpcBadge.vue';

const STAGES = { QUESTION: 'question', PENDING: 'pending', RESULT: 'result' };

export default {
  name: 'LandingQuestion',
  components: {
    IconVtb,
    DigitalTimer,
    MarqueeText,
    ResultBlock,
    ButtonLogin,
    LandingQuestionLoader,
    TransitionFade,
    NpcBadge,
  },
  data() {
    return {
      burn: false,
      supertitle: 'проверь свои навыки',
      title: 'Создай свой CJM',
      subtitle:
        'Ни один из выбранных вариантов не будет абсолютно верным. Чтобы ответить на вопрос, необходимо провести исследование, например, с помощью CJM. Хочешь узнать, что это такое? Или ты уже слышал о маршрутной карте пользователя и готов испытать свои силы в ее построении?',
      questionText:
        'Мы хотим создать самый крутой интернет-магазин тортиков, но никак не можем решить, какую фичу прикрутить. Ты гуру создания продуктов? Поможешь нам?',
      answers: [
        'Сделать конфигуратор тортиков',
        'Добавить подписку на тортики',
        'AI помошник для выбора тортика',
        'Без CJM не разобраться',
      ],
      runningText: 'Проверь за 10 минут, умеешь ли ты делать крутые продукты',
      stages: STAGES,
      currentStage: STAGES.QUESTION,
      withBadge: true,
      userChoice: '',
    };
  },
  props: {},
  created() {},
  mounted() {},
  methods: {
    launchFire() {
      this.burn = true;
      this.withBadge = false;
      this.supertitle = 'Ты немного не успел, но ничего страшного!';
      this.title = 'Создай свой CJM';
      this.subtitle =
        'Чтобы ответить на вопрос необходимо провести исследование, например, с помощью CJM. Хочешь узнать, что это такое? Или ты уже слышал о маршрутной карте пользователя и готов испытать свои силы в ее построении?';
      setTimeout(() => {
        this.currentStage = this.stages.RESULT;
      }, 5000);
    },
    handleAnswer(answer) {
      this.userChoice = answer;
      this.currentStage = this.stages.PENDING;
      setTimeout(() => {
        this.currentStage = this.stages.RESULT;
      }, 3000);
    },
  },
  computed: {},
};
</script>
