<style lang="scss">
.results {
  &__section {
    @apply w-full md:px-0 px-4;

    &-title {
      @apply md:text-40 text-28 font-black md:leading-52 leading-36 uppercase pt-10;
      font-style: italic;
    }

    &--first {
      margin-top: 100px;

      @screen md {
        margin-top: 0;
      }
    }
  }

  &__header {
    @apply mt-5;
  }

  &__container {
    @apply w-full my-0 mx-auto;
    max-width: 736px;

    &--big {
      max-width: 1120px;
    }

    &--border {
      @apply border border-solid border-black rounded-md;
    }
  }

  &__wires {
    @apply hidden;

    @screen md {
      @apply block relative w-full;
    }

    &-item {
      @apply absolute -top-5;

      &:nth-child(2),
      &:nth-child(3) {
        @apply right-0;
      }
    }
  }

  &__row {
    @apply flex;

    &--border-t {
      @apply border-t border-solid border-black;
    }

    &--items-b {
      @apply items-end;
    }

    &--bg-white {
      @apply bg-white;
    }
  }

  &__title {
    @apply font-black text-center uppercase;
    font-size: 28px;
    line-height: 36px;

    @screen md {
      font-size: 56px;
      line-height: 96px;
    }
  }

  &__total {
    @apply md:mt-2 mt-4;

    &-supertitle {
      @apply font-black text-center uppercase;
      font-size: 15px;
      color: #009fdf;
    }
  }

  &__banner {
    @apply relative mt-8 md:mt-0;

    &::after {
      @apply absolute w-full;
      top: 0;
      z-index: -1;
      content: "";
      height: 320px;
      background-color: #a8e6ff;
      filter: blur(200px);
    }

    &-pic {
      @apply my-0 mx-auto;
    }
  }

  &__description {
    @apply text-14 md:text-18 font-normal leading-5 md:leading-28 text-center mx-auto md:mt-0 mt-8 mb-14;
    max-width: 290px;

    @screen md {
      max-width: 620px;
    }
  }

  &__buttons {
    @apply flex items-center justify-center md:flex-row flex-col;
  }

  &__work-wish {
    @apply text-center;

    &__text {
      @apply text-20 leading-7 opacity-80;
      color: #8f8f8f;
    }

    &__button {
      @apply text-20 leading-7 inline-block opacity-80 border-b mt-2 cursor-pointer;
      border-color: #0094ff;
      color: #0094ff;

      &:hover {
        border-color: transparent;
      }
    }
  }

  &__button {
    width: 100%;
    height: 68px;

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 14px;

      @screen md {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }

    &--outline {
      @apply text-black border-black;
    }

    @screen md {
      width: 272px;
    }
  }

  &__feedback {
    @apply mt-20;

    &-container {
      @apply pb-10;
    }

    &-box {
      @apply relative py-10 md:py-8 md:mt-8 mt-0;
    }

    &-buttons {
      @apply relative flex items-center pl-6 md:pl-11;
      z-index: 2;
    }

    &-button {
      @apply relative outline-none;
      &:not(:last-child) {
        @apply md:mr-8 mr-4;
      }

      &:not(:disabled) {
        &:hover,
        &:focus {
          @apply outline-none;
          color: #019ce7;
        }

        &:active {
          @apply outline-none;
          opacity: 0.7;
        }
      }

      &:disabled {
        cursor: not-allowed;
      }

      &--active {
        color: #019ce7;
      }

      &--active {
        &::before {
          @apply absolute;
          content: "";
          left: 50%;
          top: 50%;
          z-index: -1;
          transform: translate(-50%, -50%);
          width: 60px;
          height: 60px;
          background: #019ce7;
          filter: blur(25px);
        }
      }

      &__icon {
        width: 35px;
        height: 35px;

        @screen md {
          width: 64px;
          height: 64px;
        }
      }
    }

    &-panel {
      @apply absolute top-0;
      width: 100%;
      max-width: 568px;
      min-width: 316px;
      height: 128px;
      z-index: 1;
      left: -24px;

      @screen md {
        left: -42px;
      }
    }

    &-form {
      @apply pt-8 mx-8;
    }

    &-textarea {
      @apply text-20 w-full py-5 px-6 mb-6 rounded-md;
      min-height: 120px;
      background-color: #f6f8ff;
      resize: none;
    }
  }

  &__footer {
    @apply mt-24 md:mx-auto;

    &-container {
      @apply border-t-2 border-black pt-12;
    }
  }
}
</style>

<template>
  <div class="results">
    <div class="results__wires">
      <ResultsWire01 ref="resultsWire01" class="results__wires-item" />
      <ResultsWire02 ref="resultsWire02" class="results__wires-item" />
      <ResultsWire03 ref="resultsWire03" class="results__wires-item" />
    </div>
    <div class="results__header">
      <game-header-with-logo />
    </div>
    <section class="results__section results__section--first">
      <div class="results__container results__container--big">
        <h1 class="results__title">тортик получен</h1>
        <div v-for="(item, idx) in stageResults" :key="idx" class="results__total">
          <p class="results__total-supertitle">{{ item.supertitle }}</p>
        </div>
        <div class="results__banner">
          <img src="/images/results-banner.png" alt class="results__banner-pic" />
          <div class="results__banner-shine"></div>
        </div>
        <p class="results__description">{{ resultsText }}</p>
        <div v-if="!workWishResult" class="results__buttons">
          <button
            class="results__button results__button--outline g-vtb-btn g-vtb-btn--outline"
            @click="sendWorkWish({ wish: 'negative' })"
          >Спасибо, нет</button>
          <button
            class="results__button g-vtb-btn"
            @click="sendWorkWish({ wish: 'positive' })"
          >Да, хочу</button>
        </div>
        <div v-else>
          <div v-if="workWishResult === 'negative'" class="results__work-wish">
            <p
              class="results__work-wish__text"
            >Спасибо за ваш ответ! Будем рады узнать, если вы передумаете</p>
            <p
              class="results__work-wish__button"
              @click="sendWorkWish({ wish: 'positive' })"
            >Я передумал и хочу работать в ВТБ</p>
          </div>
          <div v-else class="results__work-wish">
            <p class="results__work-wish__text">Спасибо за твой ответ!</p>
            <p class="results__work-wish__text">Будем рады видеть тебя в нащей команде :)</p>
          </div>
        </div>
      </div>
    </section>
    <section class="results__share results__section results__container">
      <SocialShare class="results__share-list mt-10 md:mt-24" :title="false">
        <template v-slot:result-title>
          <h3 class="share__title mb-8">
            {{ resultShareText.textBefore }}
            <br />
            <span class="share__title--shadow">{{ resultShareText.textHighlight }}</span>
            <br />
            {{ resultShareText.textAfter }}
          </h3>
        </template>
      </SocialShare>
    </section>
    <section v-if="!hasFeedback" class="results__feedback results__section">
      <div class="results__feedback-container results__container results__container--border">
        <h2 class="results__section-title pl-3 md:pl-8">Понравилась игра?</h2>
        <div class="results__feedback-box">
          <div class="results__feedback-buttons">
            <button
              v-for="(button, idx) in feedbackButtons"
              :key="`feedback_btn_${idx}`"
              class="results__feedback-button"
              :class="
                feedbackRating === idx + 1
                  ? 'results__feedback-button--active'
                  : ''
              "
              :disabled="feedbackRating"
              @click="sendFeedback({ rating: idx + 1, comment: '' })"
            >
              <component
                :is="button"
                :active="feedbackRating === idx + 1"
                class="results__feedback-button__icon"
              />
            </button>
          </div>
          <IconFeedbackPanel class="results__feedback-panel" />
        </div>
        <form v-if="feedbackFormShow" class="results__feedback-form">
          <textarea
            v-model="feedbackMessage"
            :disabled="feedbackSent"
            name="feedback"
            class="results__feedback-textarea"
          ></textarea>
          <button
            class="g-vtb-btn"
            :disabled="feedbackMessage.length < 3 || feedbackSent"
            @click.prevent="
            () =>
              sendFeedback({
                rating: feedbackRating,
                comment: feedbackMessage,
              })
            "
          >Отправить</button>
        </form>
      </div>
    </section>
    <button class="g-vtb-btn mt-12 mx-auto block" @click="start">Рестарт</button>
    <div class="results__section results__footer">
      <div class="results__footer-container results__container results__container--big">
        <footer-content />
      </div>
    </div>
  </div>
</template>

<script>
import FooterContent from '../components/FooterContent.vue';
import GameHeaderWithLogo from '../components/GameHeaderWithLogo.vue';
import SocialShare from '../components/SocialShare.vue';
import IconFeedbackPanel from '@/components/Icons/IconFeedbackPanel.vue';
import IconFeedback1 from '@/components/Icons/feedback/IconFeedback1.vue';
import IconFeedback2 from '@/components/Icons/feedback/IconFeedback2.vue';
import IconFeedback3 from '@/components/Icons/feedback/IconFeedback3.vue';
import IconFeedback4 from '@/components/Icons/feedback/IconFeedback4.vue';
import IconFeedback5 from '@/components/Icons/feedback/IconFeedback5.vue';
import ResultsWire01 from '@/components/Icons/ResultsWire01.vue';
import ResultsWire02 from '@/components/Icons/ResultsWire02.vue';
import ResultsWire03 from '@/components/Icons/ResultsWire03.vue';
import textData from '@/assets/text/sprint-outro-text.json';
import { STAGE_NAMES } from '@/consts.js';
import axios from 'axios';
import useGameState from '@/composables/useGameState';
import { MODAL_NAMES } from '@/consts.js';

export default {
  components: {
    GameHeaderWithLogo,
    FooterContent,
    SocialShare,
    IconFeedbackPanel,
    IconFeedback1,
    IconFeedback2,
    IconFeedback3,
    IconFeedback4,
    IconFeedback5,
    ResultsWire01,
    ResultsWire02,
    ResultsWire03,
    // gFeedbackForm: () => import("@geeckocom/g-feedback-form"),
  },
  data() {
    return {
      feedbackSent: false,
      descs: textData,
      stageNames: STAGE_NAMES,
      loading: false,
      feedbackFormShow: false,
      feedbackMessage: '',
      feedbackRating: false,
      feedbackButtons: [
        'IconFeedback1',
        'IconFeedback2',
        'IconFeedback3',
        'IconFeedback4',
        'IconFeedback5',
      ],
    };
  },
  setup() {
    const { restart, stageData, updateGameState } = useGameState();
    updateGameState();
    return { restart, stageData, updateGameState };
  },
  computed: {
    resultsText() {
      if (this.stageData?.correct <= 4) {
        return 'Совсем неплохо для начала! Если ты захочешь развиваться в данном направлении, то сможешь добиться значимых успехов в карьере! Готов ли ты развивать свои способности и умения, чтобы присоединиться к команде ВТБ и развивать лучшие банковские продукты в России?';
      } else if (this.stageData?.correct <= 6) {
        return 'Весьма неплохо! Готов ли ты развивать свои способности и умения, чтобы присоединиться к команде ВТБ и развивать лучшие банковские продукты в России?';
      } else {
        return 'Отличная работа! ВТБ нужны такие специалисты, вроде тебя. Ты бы хотел вступить в нашу команду, чтобы развивать лучшие банковские продукты в России?';
      }
    },
    resultShareText() {
      if (this.stageData?.correct <= 4) {
        return {
          textBefore: 'Ты в начале',
          textAfter: 'пути!',
          textHighlight: 'многообещающего',
        }
      } else if (this.stageData?.correct <= 6) {
        return {
          textBefore: 'Результат уже',
          textAfter: 'внимания!',
          textHighlight: 'заслуживает',
        }
      } else {
        return {
          textBefore: 'Ты показал ',
          textAfter: 'мастер-класс!',
          textHighlight: 'настоящий',
        }
      }
    },
    stageResults() {
      if (!this.stageData) {
        return [];
      }
      return [this.stageData].map((item) => {
        return {
          image: `/images/sprint-${item.serial}.png`,
          supertitle:
            item.serial === 3
              ? 'Ответ отправлен'
              : `правильных ответов: ${item.correct} из ${item.answered_stage_questions}`,
          text:
            +item.serial === 3
              ? 'Отлично! Теперь ты точно попадешьв отдел рекрутинга ВТБ. Этого многое стоит, у тебя есть все шансы попасть в команду!'
              : this.descs[item.serial][this.getResultValue(item)],
          title: this.stageNames[item.serial],
        };
      });
    },
    hasFeedback() {
      return this.$store.state.user.currentUser.has_feedback;
    },
    workWishResult() {
      return this.$store.state.user.currentUser.want_offer;
    },
  },
  methods: {
    async getUserInfo() {
      await this.$store.dispatch('user/fetchUser');
    },
    getResultValue(stage) {
      const percent = (stage.correct / stage.total_stage_questions) * 100;
      if (percent < 40) {
        return 'low';
      } else if (percent < 70) {
        return 'middle';
      }
      return 'high';
    },
    async start() {
      await this.restart()
        .then(this.updateGameState)
        .then(() => {
          this.$modal.hide(MODAL_NAMES.ENDOFGAME);
          this.$router.push({ name: 'CJM' });
        });
    },
    async wantOffer() {
      try {
        this.loading = true;
        await axios.post('/intentions', { state: 'positive' }).then(() => {
          this.$notify({
            type: 'success',
            text: 'Ответ успешно отправлен',
          });
        });
      } catch (e) {
        this.$notify({
          type: 'error',
          text: `${e.response.status}: ${e.response.statusText || 'Упс! Кажется, что-то пошло не так...'
            }`,
        });
      } finally {
        this.loading = false;
      }
    },
    async sendFeedback({ rating, comment }) {
      try {
        await axios.post('/feedback', { rating, comment }).then(() => {
          if (comment) {
            this.feedbackSent = true;
            this.$notify({
              type: 'success',
              text: 'Отзыв успешно отправлен! Спасибо',
            });
          }
          this.feedbackFormShow = true;
          this.feedbackRating = rating;
        });
      } catch (e) {
        this.$notify({
          type: 'error',
          text: `${e.response.status}: ${e.response.statusText || 'Упс! Кажется, что-то пошло не так...'
            }`,
        });
      } finally {
      }
    },
    async sendWorkWish({ wish }) {
      try {
        await axios.post('/intentions', { state: wish }).then(() => {
          if (wish !== 'negative') {
            this.$notify({
              type: 'success',
              text: 'Ответ успешно отправлен!',
            });
          }
        });
        this.$store.dispatch('user/fetchUser');
      } catch (e) {
        this.$notify({
          type: 'error',
          text: `${e.response.status}: ${e.response.statusText || 'Упс! Кажется, что-то пошло не так...'
            }`,
        });
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', () => {
      if (
        pageYOffset < 400 &&
        this.$refs.resultsWire01 &&
        this.$refs.resultsWire02 &&
        this.$refs.resultsWire03
      ) {
        this.$refs.resultsWire01.$el.style.transform = `translate(-${pageYOffset / 10
          }px, -${pageYOffset / 8}px)`;
        this.$refs.resultsWire02.$el.style.transform = `translate(${pageYOffset / 9
          }px, -${pageYOffset / 7}px)`;
        this.$refs.resultsWire03.$el.style.transform = `translate(${pageYOffset / 7
          }px, -${pageYOffset / 7}px)`;
      }
    });
    this.getUserInfo()
  },
};
</script>
