<style lang="scss">
.digital-interface {
  @apply flex;
}
</style>

<template>
  <div class="digital-interface">
    <component :is="digitFirst" />
    <component :is="digitSecond" />
    <slot>
      <icon-colon />
    </slot>
    <component :is="digitThird" />
    <component :is="digitFourth" />
  </div>
</template>

<script>
import IconColon from '../Icons/IconColon.vue';
import IconDigitEight from '../Icons/IconDigitEight.vue';
import IconDigitFive from '../Icons/IconDigitFive.vue';
import IconDigitFour from '../Icons/IconDigitFour.vue';
import IconDigitNine from '../Icons/IconDigitNine.vue';
import IconDigitOne from '../Icons/IconDigitOne.vue';
import IconDigitSeven from '../Icons/IconDigitSeven.vue';
import IconDigitSix from '../Icons/IconDigitSix.vue';
import IconDigitThree from '../Icons/IconDigitThree.vue';
import IconDigitTwo from '../Icons/IconDigitTwo.vue';
import IconDigitZero from '../Icons/IconDigitZero.vue';
export default {
  name: 'DigitalInterface',
  components: {
    IconDigitEight,
    IconDigitFive,
    IconDigitFour,
    IconDigitNine,
    IconDigitOne,
    IconDigitSeven,
    IconDigitSix,
    IconDigitThree,
    IconDigitTwo,
    IconDigitZero,
    IconColon,
  },
  data() {
    return {
      images: {
        0: 'icon-digit-zero',
        1: 'icon-digit-one',
        2: 'icon-digit-two',
        3: 'icon-digit-three',
        4: 'icon-digit-four',
        5: 'icon-digit-five',
        6: 'icon-digit-six',
        7: 'icon-digit-seven',
        8: 'icon-digit-eight',
        9: 'icon-digit-nine',
      },
    };
  },
  props: {
    leftNumber: {
      type: Number,
    },
    rightNumber: {
      type: Number,
    },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {
    left() {
      return this.leftNumber.toString().padStart(2, '0');
    },
    right() {
      return this.rightNumber.toString().padStart(2, '0');
    },
    digitFirst() {
      return this.images[this.left[0]];
    },
    digitSecond() {
      return this.images[this.left[1]];
    },
    digitThird() {
      return this.images[this.right[0]];
    },
    digitFourth() {
      return this.images[this.right[1]];
    },
  },
};
</script>
