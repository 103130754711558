<template>
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.2899 40.5802C31.4956 40.5802 40.5797 31.4961 40.5797 20.2903C40.5797 9.08457 31.4956 0.000488281 20.2899 0.000488281C9.08408 0.000488281 0 9.08457 0 20.2903C0 31.4961 9.08408 40.5802 20.2899 40.5802Z"
      fill="#6383A8"
    />
    <path
      d="M19.1485 27.7934H20.5658C20.5658 27.7934 20.9929 27.747 21.2109 27.5133C21.418 27.2953 21.418 26.8919 21.418 26.8919C21.418 26.8919 21.3874 24.9913 22.2751 24.7121C23.15 24.435 24.2596 26.5467 25.4422 27.3584C26.3456 27.9788 27.0252 27.8457 27.0252 27.8457L30.1923 27.8023C30.1923 27.8023 31.8513 27.6987 31.0672 26.3958C31.007 26.2922 30.6223 25.4341 28.7286 23.6735C26.7441 21.838 27.0104 22.1339 29.3953 18.952C30.8442 17.0139 31.437 15.8293 31.2447 15.3263C31.081 14.8529 30.0019 14.9703 30.0019 14.9703L26.4492 15.0008C26.4492 15.0008 26.1829 14.9703 25.9906 15.0896C25.7982 15.207 25.6809 15.4753 25.6809 15.4753C25.6809 15.4753 25.1167 16.9833 24.3631 18.2567C22.7624 20.9483 22.1262 21.0963 21.8747 20.9188C21.2789 20.5331 21.4318 19.3604 21.4318 18.52C21.4318 15.9152 21.8303 14.8213 20.6615 14.5402C20.2768 14.4505 19.9898 14.3923 18.9996 14.3765C17.7272 14.3627 16.6541 14.3864 16.0455 14.6832C15.6461 14.8835 15.3354 15.3263 15.5139 15.349C15.7506 15.3786 16.2822 15.493 16.5633 15.8787C16.9332 16.3777 16.9174 17.4923 16.9174 17.4923C16.9174 17.4923 17.1255 20.5656 16.4302 20.9474C15.942 21.2058 15.291 20.6712 13.8707 18.2291C13.1458 16.9863 12.5974 15.5956 12.5974 15.5956C12.5974 15.5956 12.4938 15.3431 12.3025 15.1951C12.0786 15.0324 11.7531 14.9732 11.7531 14.9732L8.36507 15.0028C8.36507 15.0028 7.86205 15.0186 7.6855 15.2395C7.52177 15.4319 7.66972 15.8461 7.66972 15.8461C7.66972 15.8461 10.319 22.0471 13.3233 25.1698C16.0751 28.0321 19.1978 27.8476 19.1978 27.8476L19.1485 27.7934Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconVkRounded",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
