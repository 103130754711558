<style lang="scss">
.l-why-cje-title {
  @apply uppercase font-vtb-bold;
  font-size: 32px;
  line-height: 36px;
}
@screen md {
  .l-why-cje-title {
    font-size: 56px;
    line-height: 96px;
  }
}
.l-why-cje {
  @apply md:-mx-3 -my-14 flex flex-wrap;
  &__item {
    @apply px-4 md:px-12 box-border rounded-2xl pt-14 md:pt-24 pb-4 md:pb-14 inline-block md:mx-3 
      my-6 md:my-14 relative;
    width: 100%;
    border: 2px solid #000000;

    &-title {
      @apply font-vtb-demibold mb-3 md:mb-4;
      font-size: 24px;
      line-height: 32px;
    }
    &-text {
      @apply opacity-80 text-xl leading-7;
    }
    &-icon {
      @apply absolute left-2 md:left-9 -top-16;
      width: 92px;
    }
  }
}
@screen md {
  .l-why-cje {
    &__item {
      width: calc(50% - 28px);

      &-title {
        font-size: 32px;
        line-height: 48px;
      }
      &-text {
      }
      &-icon {
        width: 140px;
      }
    }
  }
}
</style>

<template>
  <div>
    <div class="l-why-cje pt-20">
      <div v-for="item in items" :key="item.title" class="l-why-cje__item">
        <component :is="item.icon" class="l-why-cje__item-icon"></component>
        <div class="l-why-cje__item-title">{{ item.title }}</div>
        <div class="l-why-cje__item-text">{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import IconCrown from "../Icons/IconCrown.vue";
import IconDiploma from "../Icons/IconDiploma.vue";
import IconHelmetCyber from "../Icons/IconHelmetCyber.vue";
import IconNotebook from "../Icons/IconNotebook.vue";

export default {
  name: "LandingWhyCje",
  components: { IconHelmetCyber, IconCrown, IconNotebook, IconDiploma },
  data() {
    return {
      items: [
        {
          title: "Крутая профессия",
          text: "CJE находится на стыке нескольких специальностей - это профессия будущего",
          icon: "icon-helmet-cyber",
        },
        {
          title: "Нет конкуренции",
          text: "Молодая профессия. У рынка труда уже есть спрос, но ещё мало специалистов",
          icon: "icon-crown",
        },
        {
          title: "Оцени свои силы",
          text: "Расскажем про инструменты и покажем кейсы. Дадим задачи, чтобы ты проверил себя в бою",
          icon: "icon-notebook",
        },
        {
          title: "Стань CJ-специалистом",
          text: "Самые перспективные участники могут быть приглашены на работу в ВТБ",
          icon: "icon-diploma",
        },
      ],
    };
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
