<style lang="scss">
.footer {
  &-content {
    @apply relative flex flex-col md:flex-row w-full font-vtb-light text-left md:pl-48
      pt-10 md:pt-12 justify-between;
    padding-bottom: 36px;
    .geecko-logo {
      @apply md:absolute left-0 md:top-12 mb-5;
      width: 113px;
      height: 30px;
    }
    .footer-link {
      @apply mb-2 md:mb-4 block text-base md:text-xl leading-6;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.7);
      &:hover {
        color: #0094ff;
      }
    }
    .links {
      @apply md:pr-32;
    }
    p {
      @apply mt-6 md:mt-0;
      font-size: 14px;
      line-height: 24px;
      color: #bcbcbc;
      max-width: 416px;
    }
  }
}
</style>

<template>
  <div class="footer-content">
    <icon-geecko class="geecko-logo" />
    <div class="links">
      <a
        v-for="item in links"
        :key="item.name"
        :href="item.link"
        class="footer-link"
        target="_blank"
      >{{ item.name }}</a
      >
    </div>
    <p>
      ООО “Смарт Гико“, ОГРН 1187746655359, ИНН/КПП 7743264341/774301001, место
      нахождения 125008, город Москва, проезд Черепановых, дом 36, обладающее
      всеми правами в отношении Сайта.
    </p>
  </div>
</template>
<script>
import IconGeecko from './Icons/IconGeecko.vue';
import { DOCS } from '@/consts';

export default {
  components: { IconGeecko },
  name: 'FooterContent',
  data() {
    return {
      links: DOCS,
    };
  },
};
</script>
