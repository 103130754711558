<style lang='scss' scoped>
.vtb-logo {
  @apply mx-auto relative -left-8;
  width: 68px;
}
</style>

<template>
  <game-header class="pl-5 pr-6 md:px-8" hideProgress>
    <div class="flex-grow">
      <img src="/images/vtb-logo.png" alt="vtb logo" class="vtb-logo" />
    </div>
  </game-header>
</template>

<script>
import GameHeader from "./GameHeader.vue";
export default {
  name: "GameHeaderWithLogo",
  components: { GameHeader },
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
