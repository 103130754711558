<template>
  <svg width="28" height="40" viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.328 4H7.02399L10.32 7.456H18.16L21.328 4ZM6 5.37598V19.68L9.456 16.416V8.47998L6 5.37598ZM22.384 5.37598V19.68L18.928 16.576V8.67198L22.384 5.37598ZM7.37598 20.2241C8.14398 20.8001 8.90131 21.3867 9.64798 21.9841H18.384L20.624 20.2241L18.384 18.5281H9.64798L7.37598 20.2241ZM22.384 35.104L18.928 31.808V23.936L22.384 20.8V35.104ZM10.352 33.0559L7.05603 36.5119H21.36L18.256 33.0559H10.352Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconDigitNine',
  components: {},
  data() {
    return {};
  },
  props: {},
  created() { },
  mounted() { },
  methods: {},
  computed: {},
};
</script>
