<template>
  <svg width="28" height="40" viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.384 19.68V5.37598L18.928 8.67198V16.576L22.384 19.68ZM18.928 31.808L22.384 35.104V20.8L18.928 23.936V31.808Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconDigitOne',
  components: {},
  data() {
    return {};
  },
  props: {},
  created() { },
  mounted() { },
  methods: {},
  computed: {},
};
</script>
