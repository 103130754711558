<style lang="scss">
.digital-timer {
  @apply bg-contain bg-center bg-no-repeat;
  width: 204px;
  height: 92px;
  color: #0093ff;
  padding-top: 32px;
  padding-left: 32px;
  background-image: url('/images/landing/timer.svg');
  order: -1;

  @media screen and (min-width: 769px) {
    order: auto;
  }

  &__digits-container {
    @apply flex;
  }
}
</style>

<template>
  <div class="digital-timer">
    <digital-interface :leftNumber="numminutes" :rightNumber="numseconds" />
  </div>
</template>

<script>
import DigitalInterface from './DigitalInterface.vue';

export default {
  components: {
    DigitalInterface,
  },
  name: 'DigitalTimer',
  props: {
    // value in seconds
    interval: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      currentTime: Math.round(this.interval),
      timer: null,
    };
  },
  computed: {
    numminutes() {
      return Math.floor((((this.currentTime % 31536000) % 86400) % 3600) / 60);
    },
    numseconds() {
      return (((this.currentTime % 31536000) % 86400) % 3600) % 60;
    },
  },
  watch: {
    interval(val) {
      this.currentTime = val;
    },
  },
  mounted() {
    this.startTimer();
  },
  destroyed() {
    this.stopTimer();
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime -= 1;

        if (this.currentTime <= 0) {
          this.stopTimer();
          this.$emit('onStop');
        }
      }, 1000);
    },
    stopTimer() {
      clearTimeout(this.timer);
    },
  },
};
</script>
