<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.5092 9.65247V12.1427H20.9996V20.814H18.5092L18.5092 23.3041H12.9058V20.8139H18.5092L18.5092 18.9463C17.7532 19.391 16.9082 19.6133 16.0188 19.6133C13.2615 19.6133 11.038 17.3899 11.038 14.6329C11.038 11.8759 13.2615 9.65247 16.0188 9.65247H16.2856H18.5092ZM16.0188 17.0786C16.7303 17.0786 17.3529 16.8118 17.7976 16.3672C18.2424 15.9225 18.5092 15.2999 18.4647 14.5884C18.4647 13.8769 18.1979 13.2544 17.7532 12.8097C17.3529 12.4095 16.7748 12.1427 16.1522 12.0982H15.9743C15.3072 12.0982 14.6846 12.365 14.2399 12.8097C13.7952 13.2989 13.5284 13.9214 13.5284 14.5884C13.5284 15.9669 14.6402 17.0786 16.0188 17.0786Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "IconGeeckoRounded",
  components: {},
  data() {
    return {};
  },
};
</script>
