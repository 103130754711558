<style lang="scss">
.l-cje-descr {
  @apply mr-0 md:mr-6 mb-8 md:mb-0;
  font-size: 24px;
  line-height: 32px;
  width: 100%;
  &__title {
    color: #009fdf;
  }
  &__text {
    font-size: 24px;
    line-height: 32px;
  }
  &__button {
    @apply mt-5;
  }
}
.l-cje-target {
  @apply relative flex-grow pr-16 md:top-4;
  &__item {
    @apply px-6 rounded-full w-max md:ml-auto md:mr-0 mb-4;
    --height: 60px;
    height: var(--height);
    line-height: calc(var(--height) - 4px);
    font-size: 22px;
    border: 2px solid #000000;
    &:last-child {
      margin-bottom: 0;
    }
    &:first-child {
      @apply mt-4 md:mt-0;
    }
  }
  &:before {
    @apply md:absolute whitespace-nowrap;
    content: 'Кому подойдёт';
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #a9a9a9;
    font-size: 16px;
    line-height: 24px;
    top: 0;
    right: auto;
    left: 0;
  }
}
@screen md {
  .l-cje-descr {
    font-size: 48px;
    line-height: 63px;
    width: 57%;
  }
  .l-cje-target {
    &:before {
      transform: rotate(90deg);
      font-size: 25px;
      line-height: 38px;
      top: 94px;
      right: -80px;
      left: auto;
    }
  }
}
</style>

<template>
  <div class="flex flex-col md:flex-row">
    <div class="l-cje-descr">
      <div class="l-cje-descr__title">{{ title }}</div>
      <div class="l-cje-descr__text">{{ text }}</div>
      <button-login text="Создать CJM" class="l-cje-descr__button" />
    </div>
    <div class="l-cje-target">
      <div v-for="item in targetItems" :key="item" class="l-cje-target__item">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
import ButtonLogin from '@/components/ButtonLogin.vue';

export default {
  name: 'LandingCjeDescr',
  components: { ButtonLogin },
  data() {
    return {
      title: 'Customer Journey Map',
      text:
        'Customer Journey Map - это инструмент исследования того, как пользователь взаимодействует с продуктом. Здесь используется маршрутная карта шагов, целей, эмоций, начиная от осознания потребностей, заканчивая потребностью дать отзыв. Customer Journey Expert (CJE) проектирует и использует CJM для продуктовых исследований.',
      targetItems: ['Project manager', 'UI/UX designer', 'Business analyst'],
    };
  },
};
</script>
