<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20Z"
      fill="#1DA1F2"
    />
    <path
      d="M19.401 16.2565L19.443 16.9485L18.7435 16.8638C16.1974 16.539 13.973 15.4373 12.0844 13.5872L11.1611 12.6692L10.9233 13.3471C10.4197 14.8583 10.7414 16.4542 11.7907 17.5276C12.3503 18.1208 12.2243 18.2055 11.2591 17.8524C10.9233 17.7394 10.6295 17.6547 10.6016 17.6971C10.5036 17.7959 10.8394 19.0812 11.1052 19.5896C11.4689 20.2958 12.2104 20.9878 13.0218 21.3974L13.7072 21.7222L12.8958 21.7364C12.1124 21.7364 12.0844 21.7505 12.1684 22.0471C12.4482 22.9651 13.5534 23.9396 14.7844 24.3633L15.6518 24.6599L14.8964 25.1118C13.7772 25.7615 12.4622 26.1287 11.1471 26.157C10.5176 26.1711 10 26.2276 10 26.27C10 26.4112 11.7067 27.2021 12.7 27.5128C15.6798 28.4308 19.2191 28.0354 21.8772 26.4677C23.7658 25.3519 25.6543 23.1346 26.5357 20.9878C27.0113 19.8438 27.487 17.7536 27.487 16.7508C27.487 16.1011 27.5289 16.0164 28.3124 15.2396C28.774 14.7877 29.2077 14.2933 29.2916 14.1521C29.4315 13.8838 29.4175 13.8838 28.7041 14.1239C27.515 14.5476 27.3471 14.4911 27.9346 13.8555C28.3683 13.4036 28.8859 12.5844 28.8859 12.3443C28.8859 12.302 28.6761 12.3726 28.4383 12.4997C28.1865 12.6409 27.6269 12.8528 27.2072 12.9799L26.4518 13.22L25.7663 12.7539C25.3885 12.4997 24.8569 12.2172 24.5771 12.1325C23.8637 11.9347 22.7725 11.963 22.129 12.189C20.3803 12.8245 19.2751 14.4628 19.401 16.2565Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconTwRounded",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
