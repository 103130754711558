<template>
  <svg
    width="114"
    height="30"
    viewBox="0 0 114 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#icon-geecko)">
      <path
        d="M12.8155 10.621V6.37061H8.99699C8.87949 6.37061 8.70326 6.37061 8.58576 6.37061C3.82734 6.37061 0.0088501 10.1552 0.0088501 14.8715C0.0088501 19.5295 3.82734 23.3724 8.58576 23.3724C10.1132 23.3724 11.5818 22.9648 12.8742 22.2661V25.5267H17.1627V10.621H12.8155ZM11.5818 17.841C10.8181 18.5979 9.76068 19.0637 8.58576 19.0637C6.23592 19.0637 4.29731 17.1423 4.29731 14.8133C4.29731 13.6488 4.76728 12.6007 5.53097 11.8438C6.29467 11.0868 7.3521 10.621 8.52702 10.621C8.64451 10.621 8.70326 10.621 8.82075 10.621C9.87818 10.6793 10.8769 11.1451 11.5818 11.8438C12.3455 12.6007 12.8155 13.6488 12.8155 14.8133C12.8155 16.036 12.3455 17.084 11.5818 17.841Z"
        fill="black"
      />
      <path
        d="M73.3234 20.8687C71.796 22.3825 69.6224 23.3723 67.2726 23.3723C62.5729 23.3723 58.6957 19.5877 58.6957 14.8715C58.6957 12.5425 59.6356 10.3881 61.2218 8.87429C62.7492 7.36043 64.9228 6.37061 67.2726 6.37061H71.5611V10.621H67.2726C66.0977 10.621 65.0402 11.0868 64.2765 11.8438C63.5129 12.6007 63.0429 13.6487 63.0429 14.8133C63.0429 17.1423 64.9815 19.0637 67.3313 19.0637C68.5063 19.0637 69.5637 18.5979 70.3274 17.841L73.3234 20.8687Z"
        fill="black"
      />
      <path
        d="M12.7569 25.4688H3.12256V29.7192H12.7569V25.4688Z"
        fill="black"
      />
      <path
        d="M95.4713 19.1221H91.1829V23.3725H95.4713V19.1221Z"
        fill="black"
      />
      <path
        d="M86.9528 14.8718H91.1825V19.1223H82.6643V23.3727H78.3759V0.0244141H82.6643V13.0668L89.4201 6.37095H95.4709L86.9528 14.8718Z"
        fill="black"
      />
      <path
        d="M105.575 6.37061C105.458 6.37061 105.34 6.37061 105.223 6.37061C105.105 6.37061 104.988 6.37061 104.87 6.37061C102.638 6.42883 100.64 7.36043 99.113 8.87429C97.5856 10.3881 96.5869 12.5425 96.5869 14.8715C96.5869 19.5295 100.405 23.3723 105.164 23.3723C107.514 23.3723 109.687 22.4407 111.215 20.8687C112.742 19.3548 113.741 17.2005 113.741 14.8715C113.799 10.2717 110.098 6.54528 105.575 6.37061ZM109.511 19.0637H100.934V10.621H109.511V19.0637Z"
        fill="black"
      />
      <path
        d="M75.8497 10.6211H71.5613V14.8715H75.8497V10.6211Z"
        fill="black"
      />
      <path
        d="M37.0186 10.621H32.7302V6.37061H28.4417C26.0919 6.37061 23.9183 7.30221 22.3909 8.87429C20.8635 10.3881 19.8648 12.5425 19.8648 14.8715C19.8648 19.5295 23.6833 23.3723 28.4417 23.3723H32.7302V19.1219H28.4417C26.8556 19.1219 25.5044 18.2485 24.7407 17.0258H37.0186V10.621ZM24.447 13.3576C24.5057 13.1247 24.6232 12.95 24.7407 12.7754C24.917 12.4842 25.1519 12.1931 25.4457 11.902C26.2094 11.1451 27.2668 10.6793 28.4417 10.6793C28.5592 10.6793 28.618 10.6793 28.7354 10.6793C29.7929 10.7375 30.7328 11.2033 31.4378 11.902C31.6727 12.1349 31.9077 12.4842 32.1427 12.7754C32.2602 12.95 32.319 13.1829 32.4364 13.3576H24.447Z"
        fill="black"
      />
      <path
        d="M56.4052 10.6796H52.1168V6.4292H47.8283C45.4785 6.4292 43.3049 7.3608 41.7775 8.93288C40.2501 10.4467 39.2514 12.6011 39.2514 14.9301C39.2514 19.5881 43.0699 23.4309 47.8283 23.4309H52.1168V19.1805H47.8283C46.2422 19.1805 44.891 18.3071 44.1273 17.0844H56.4052V10.6796ZM43.8923 13.358C43.9511 13.1251 44.0686 12.9504 44.1861 12.7757C44.3623 12.4846 44.5973 12.1935 44.891 11.9024C45.6547 11.1454 46.7121 10.6796 47.8871 10.6796C48.0045 10.6796 48.0633 10.6796 48.1808 10.6796C49.2382 10.7379 50.1782 11.2037 50.8831 11.9024C51.1181 12.1353 51.3531 12.4846 51.5881 12.7757C51.7055 12.9504 51.7643 13.1833 51.8818 13.358H43.8923Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="icon-geecko">
        <rect width="113.75" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconGeecko",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
