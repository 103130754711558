<template>
  <svg width="28" height="40" viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.02399 4H21.328L18.16 7.456H10.32L7.02399 4ZM6 19.68V5.37598L9.456 8.47998V16.416L6 19.68ZM22.384 19.68V5.37598L18.928 8.67198V16.576L22.384 19.68ZM9.64798 21.9841C8.90131 21.3867 8.14398 20.8001 7.37598 20.2241L9.64798 18.5281H18.384L20.624 20.2241L18.384 21.9841H9.64798ZM9.456 24.128L6 20.8V35.104L9.456 31.968V24.128ZM22.384 35.104L18.928 31.808V23.936L22.384 20.8V35.104ZM10.352 33.0559L7.05603 36.5119H21.36L18.256 33.0559H10.352Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconDigitEight',
  components: {},
  data() {
    return {};
  },
  props: {},
  created() { },
  mounted() { },
  methods: {},
  computed: {},
};
</script>
