<template>
  <svg
    viewBox="0 0 89 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.64905 3.85522L6.19943 7.79864H26.288L27.7376 3.85522H7.64905ZM5.47416 9.76945L4.02454 13.712H24.1131L25.5627 9.76945H5.47416ZM3.3038 15.6837L1.85417 19.6262H21.9427L23.3914 15.6837H3.3038Z"
      fill="#009FDF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M44.5611 21.1288V21.212C45.4792 21.5466 46.3561 22.1318 46.8156 22.5076C48.2352 23.6779 48.8618 25.224 48.8618 27.23C48.8618 30.5326 47.1079 32.9144 44.2268 33.6669C43.3498 33.9174 42.4318 34.0015 40.719 34.0015H31.9934V9.84509H39.7178C41.3045 9.84509 42.3898 9.9283 43.3498 10.1788C46.1478 10.9313 47.9849 12.8541 47.9849 15.9043C47.9849 17.5346 47.4002 18.9143 46.4402 19.8332C46.0227 20.251 45.4381 20.753 44.5611 21.1288ZM36.8367 23.6779V30.0306H40.1773C41.1785 30.0306 42.0974 29.9474 42.7661 29.3202C43.4339 28.693 43.7262 27.8993 43.7262 26.8543C43.7262 26.0606 43.517 25.3081 43.0995 24.765C42.3898 23.8873 41.5128 23.6779 40.0101 23.6779H36.8367ZM36.7947 19.4985H39.5086C40.3435 19.4985 40.761 19.4985 41.1785 19.3733C42.3066 19.0386 43.0164 18.0365 43.0164 16.6568C43.0164 15.0275 42.2646 14.3161 41.1785 13.9824C40.719 13.8571 40.2604 13.8151 39.3414 13.8151H36.7947V19.4985ZM56.1268 34.0015V13.8983H49.1541V9.84509H68.5283L67.1507 13.8983H60.9701V34.0015H56.1268ZM70.031 34.0015V9.84509H85.7311L84.3535 13.8983H74.8332V19.248H78.7164C81.8478 19.248 83.4766 20.0005 84.6038 20.9614C85.4808 21.7139 86.9834 23.3853 86.9834 26.6879C86.9834 29.9474 85.4387 31.7441 84.1443 32.6639C82.6416 33.708 81.0549 34.0015 77.8394 34.0015H70.031ZM74.8332 29.9474H78.4661C79.8436 29.9474 80.6786 29.6128 81.2632 28.8603C81.5555 28.4845 81.973 27.8152 81.973 26.5617C81.973 25.3081 81.5555 24.4304 80.7206 23.8032C80.22 23.4274 79.5513 23.2189 78.4241 23.2189H74.8332V29.9474Z"
      fill="#0A2973"
    />
  </svg>
</template>

<script>
export default {
  name: "IconVtb",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
