<template>
  <svg width="28" height="40" viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="12" y="12" width="4" height="4" fill="currentColor" />
    <rect x="12" y="24" width="4" height="4" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'IconColon',
  components: {},
  data() {
    return {};
  },
  props: {},
  created() { },
  mounted() { },
  methods: {},
  computed: {},
};
</script>
