<style lang='scss'>
.l-cje-tasks {
  @apply pb-0 md:pb-28 pt-28;

  &__box {
    @apply flex-col mb-4 md:mb-0;

    @media screen and (min-width: 769px) {
      @apply flex justify-between items-center flex-row;
    }
  }

  &__title {
    @apply uppercase font-vtb-bold text-left;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 0.01em;
    margin-bottom: 16px;
    max-width: 644px;

    @screen md {
      @apply text-right;
      font-size: 56px;
      line-height: 72px;
      margin-bottom: 40px;
    }
  }

  &__row {
    @apply flex items-center justify-between w-full;
  }

  &__col {
    &--description {
      @apply md:w-auto w-full mr-0 md:mr-12;
      max-width: 656px;
    }

    &--cake {
      display: none;

      @media screen and (min-width: 1024px) {
        @apply block;
        transform: scale(-1, 1);
      }
    }
  }

  &__button {
    @apply mt-32;
  }

  &__description {
    max-width: 380px;

    &-text {
      @apply text-14 leading-20;

      &:not(:last-child) {
        @apply mb-6;
      }

      @media screen and (min-width: 769px) {
        @apply text-24 leading-32;
      }
    }
  }

  &__button {
    margin-top: 40px;

    @media screen and (min-width: 769px) {
      margin-top: 116px;
    }
  }

  &__time {
    @apply text-left mb-4;

    @media screen and (min-width: 769px) {
      @apply text-left mb-0;
    }

    &-count {
      @apply flex items-center mt-2;
    }

    &-how {
      font-size: 21px;
    }

    &-icon {
      margin-right: 10px;
    }
  }
}
</style>

<template>
  <section class="l-cje-tasks">
    <div class="l-cje-tasks__container">
      <div class="l-cje-tasks__box">
        <div class="l-cje-tasks__time">
          <p class="l-cje-tasks__time-text">Прохождение</p>
          <div class="l-cje-tasks__time-count">
            <div class="l-cje-tasks__time-icon">
              <IconTime />
            </div>
            <span class="l-cje-tasks__time-how">15 минут</span>
          </div>
        </div>
        <h4 class="l-cje-tasks__title">Реальная задача CJ-специалиста</h4>
      </div>
      <div class="l-cje-tasks__row">
        <div class="l-cje-tasks__col l-cje-tasks__col--cake">
          <Cake />
        </div>
        <div class="l-cje-tasks__col l-cje-tasks__col--description">
          <div class="l-cje-tasks__description">
            <p class="l-cje-tasks__description-text">
              Вместе с нашими специалистами, Картофелиной и Железякой,
              разработайте идеальную CJM для увеличения продаж тортиков.
            </p>
            <p class="l-cje-tasks__description-text">
              И не забывай, что тортиков много не бывает!
            </p>
          </div>
          <button-login text="Создать CJM" class="l-cje-tasks__button" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonLogin from '@/components/ButtonLogin.vue';
import Cake from "@/components/LandingPage/Cake.vue";
import IconTime from "@/components/Icons/IconTime.vue";

export default {
  name: "LandingCjeTasks",
  components: { Cake, IconTime, ButtonLogin },
  data() {
    return {
      stages: [
        {
          image: "/images/sprint-2.png",
          title: "Планирование<br />и проектирование",
        },
        {
          image: "/images/sprint-1.png",
          title: "Инициирование<br />и анализ",
        },
        {
          image: "/images/sprint-3.png",
          title: "Защита и запуск<br />проекта",
        },
      ],
    };
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
