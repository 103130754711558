<style lang="scss">
.login-btn {
  @apply font-vtb-demibold text-18 flex items-center pl-12 pr-16 rounded whitespace-nowrap
    cursor-pointer justify-center w-full md:w-max;
  height: 60px;
  background: #0094ff;
  color: #ffffff;
  &:hover {
    background: #0094ff;
    box-shadow: 0px 8px 24px -4px rgba(0, 163, 255, 0.8);
  }
  &:hover:active {
    background: #0085e6;
    box-shadow: 0px 8px 24px -4px rgba(0, 163, 255, 0.8);
  }
}

@screen lg {
  .login-btn {
    height: 67px;
  }
}
</style>

<template>
  <button class="login-btn" @click="login">
    <icon-geecko-rounded class="mr-4" />{{ text }}
  </button>
</template>

<script>
import IconGeeckoRounded from './Icons/IconGeeckoRounded.vue';
export default {
  name: 'ButtonLogin',
  components: { IconGeeckoRounded },
  data() {
    return {};
  },
  props: {
    text: {
      type: String,
      default: 'Начать игру',
    },
  },
  created() {},
  mounted() {},
  methods: {
    login() {
      this.$store.dispatch('user/login');
    },
  },
  computed: {},
};
</script>
