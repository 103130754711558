<template>
  <svg width="28" height="40" viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.02399 4H21.328L18.16 7.456H10.32L7.02399 4ZM22.384 5.37598V19.68L18.928 16.576V8.67198L22.384 5.37598ZM18.928 31.808L22.384 35.104V20.8L18.928 23.936V31.808Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconDigitSeven',
  components: {},
  data() {
    return {};
  },
  props: {},
  created() { },
  mounted() { },
  methods: {},
  computed: {},
};
</script>
