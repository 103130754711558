<style lang="scss">
.cake {
  &__container {
    @apply relative;
  }

  &__item {
    @apply absolute;

    &--cake {
      top: -200px;
      right: 85px;
      z-index: 3;

      animation: cake-move 2s ease-in-out infinite alternate;
    }

    &--platform {
      right: 30px;
      top: -20px;
      z-index: 2;
      animation: platform-move 2s ease-in-out infinite alternate;
    }

    &--platform-back {
      right: 67px;
      top: -20px;
      animation: platform-move 2s ease-in-out infinite alternate;
    }

    &--wire-1 {
      top: -264px;
      left: -75px;
      z-index: 2;
      animation: wire-move 2s ease-in-out infinite alternate;
    }

    &--wire-2 {
      top: -242px;
      left: -302px;
      z-index: 2;
      animation: wire-move 2s ease-in-out infinite alternate;
    }

    &--wire-3 {
      top: -387px;
      left: -39px;
      z-index: 2;
      animation: wire-move 2s ease-in-out infinite alternate;
    }

    &--shine-1 {
      top: 25px;
      right: 165px;
      z-index: 1;
      width: 56px;
      height: 56px;
      background-color: #00b6ff;
      filter: blur(20px);
      animation: shine-1 2s ease-in-out infinite alternate;
    }

    &--shine-2 {
      right: 115px;
      top: -50px;
      z-index: 1;
      width: 144px;
      height: 144px;
      background-color: #00b6ff;
      opacity: 0.5;
      filter: blur(100px);
      animation: shine-2 2s ease-in-out 2s infinite alternate;
    }

    &--shine-3 {
      top: -134px;
      right: 0;
      z-index: 1;
      width: 400px;
      height: 400px;
      background-color: #a8e6ff;
      opacity: 0.5;
      filter: blur(200px);
      animation: shine-2 2s ease-in-out 4s infinite alternate;
    }
  }
}

@keyframes cake-move {
  0% {
    transform: translate(0px, -15px) rotate(-1deg);
  }

  100% {
    transform: translate(0px, 0px) rotate(1deg);
  }
}

@keyframes platform-move {
  0% {
    transform: translate(2px, 5px) rotate(1deg);
  }

  100% {
    transform: translate(-2px, -5px) rotate(0deg);
  }
}

@keyframes wire-move {
  0% {
    transform: translate(2px, 10px) rotate(1deg);
  }

  100% {
    transform: translate(-3px, -5px) rotate(0deg);
  }
}

@keyframes shine-1 {
  0% {
    opacity: .4;
  }

  100% {
    opacity: 1;
  }
}

@keyframes shine-2 {
  0% {
    opacity: .2;
  }

  100% {
    opacity: .8;
  }
}
</style>

<template>
  <div class="cake">
    <div class="cake__container">
      <IconCake class="cake__item cake__item--cake" />
      <div class="cake__item cake__item--shine-1"></div>
      <div class="cake__item cake__item--shine-2"></div>
      <div class="cake__item cake__item--shine-3"></div>
      <IconPlatformBack class="cake__item cake__item--platform-back" />
      <IconPlatform class="cake__item cake__item--platform" />
      <IconWire1 class="cake__item cake__item--wire-1" />
      <IconWire2 class="cake__item cake__item--wire-2" />
      <IconWire3 class="cake__item cake__item--wire-3" />
    </div>
  </div>
</template>

<script>
import IconCake from "@/components/Icons/cake/IconCake.vue";
import IconPlatform from "@/components/Icons/cake/IconPlatform.vue";
import IconPlatformBack from "@/components/Icons/cake/IconPlatformBack.vue";
import IconWire1 from "@/components/Icons/cake/IconWire01.vue";
import IconWire2 from "@/components/Icons/cake/IconWire02.vue";
import IconWire3 from "@/components/Icons/cake/IconWire03.vue";
export default {
  name: "Cake",
  components: {
    IconCake,
    IconPlatform,
    IconPlatformBack,
    IconWire1,
    IconWire2,
    IconWire3,
  },
};
</script>

