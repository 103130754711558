<style lang='scss'>
.l-share {
  @apply md:text-center pt-10 md:pt-24 pb-16 md:pb-24 mt-24;
  border-bottom: 2px solid #000000;
  &__title {
    @apply font-vtb-bold uppercase;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 0.01em;
  }
  & a {
    &:hover {
      @apply opacity-80;
    }
  }

  &__title {
    @apply text-24 md:text-40 leading-28 md:leading-18 text-center mx-auto mb-10 md:mb-20;
    max-width: 830px;
  }

  &__item-container {
    --side-indent: 6px;
    @apply text-center;
    margin-left: calc(-1 * var(--side-indent));
    margin-right: calc(-1 * var(--side-indent));
  }
  &__item {
    @apply inline-block align-top;
    margin-left: var(--side-indent);
    margin-right: var(--side-indent);
  }
}
@screen md {
  .l-share {
    &__title {
      font-size: 40px;
      line-height: 64px;
    }
  }
}
</style>

<template>
  <div class="l-share">
    <h2 class="l-share__title">
      Расскажи о новой профессии друзьям
    </h2>
    <div class="l-share__item-container">
      <a
        v-for="social in socialNetworks"
        :key="social"
        :href="shareUrlLink(social)"
        class="l-share__item"
        target="_blank"
      >
        <slot :name="social">
          <component :is="`icon-${social}-rounded`" />
        </slot>
      </a>
    </div>
  </div>
</template>

<script>
import IconTwRounded from '@/components/Icons/IconTwRounded.vue';
import IconVkRounded from '@/components/Icons/IconVkRounded.vue';
import IconVtb from '@/components/Icons/IconVtb.vue';

const DEFAULT_SOCIAL_NETWORKS = ['vk', 'tw'];
const VK_BASE_SHARE_LINK = 'https://vk.com/share.php?url=';
const TW_BASE_SHARE_LINK = 'https://www.twitter.com/share?url=';

export default {
  name: "LandingShare",
  components: { IconVkRounded, IconTwRounded, IconVtb },
  data() {
    return {};
  },
  props: {
    socialNetworks: {
      type: Array,
      validator: function (value) {
        return value.every((item) => DEFAULT_SOCIAL_NETWORKS.includes(item));
      },
      default: () => ['vk', 'tw'],
    },
  },
  created() {},
  mounted() {},
  methods: {
    shareUrlLink(platform) {
      const url = this.shareUrl || window.location.href;
      switch (platform) {
        case 'tw':
          return TW_BASE_SHARE_LINK + url;
        case 'vk':
          return VK_BASE_SHARE_LINK + url;
      }
    },
  },
  computed: {},
};
</script>
